<template>
  <div class="bp-cart-drawer-gwp-entry noselect" v-if="getIsGwpPromoTriggered">
    <div class="bp-cart-drawer-gwp-entry__flex-container">
      <bleach-image
        :key="getPromoProduct.displayTitle"
        v-if="getLineItemPackshot"
        class="bp-cart-drawer-gwp-entry__image"
        :class="{
          'bp-cart-drawer-gwp-entry__image--opt-out': !customerFreeGiftOptIn
        }"
        :src="getLineItemPackshot.original_secure_url"
        :default-size="[80, 80]"
        :alt="
          (getLineItemPackshot.metadata && getLineItemPackshot.metadata.alt) ||
            getPromoProduct.displayTitle + ' product image'
        "
      />
      <div class="bp-cart-drawer-gwp-entry__content noselect">
        <div class="bp-cart-drawer-gwp-entry__content-row">
          <div
            class="bp-cart-drawer-gwp-entry__content-title text-commerce-intro text--w-demi"
            :class="{
              'bp-cart-drawer-gwp-entry__content-title--opt-out': !customerFreeGiftOptIn
            }"
          >
            <span class="text--uppercase text--clr-lime text--w-demi">
              {{ UI_ELEMENT("cart_gwp_line_item_free") }}
            </span>
            {{ getLineItemTitle }}
          </div>
        </div>
        <div class="bp-cart-drawer-gwp-entry__content-row">
          <FormInput
            class="bp-cart-drawer-gwp-entry__checkbox text--clr-black mb-0"
            type="checkbox"
            noLabel
            inputId="marketing"
            v-model="customerFreeGiftOptIn"
          >
            <RichText
              :content="getOptInText"
              :baseClass="'text-body-small'"
              class="bp-cart-drawer-gwp-entry__checkbox-text mt-3 ml-8 text--left text--clr-black"
            />
          </FormInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import RichText from "@/components/RichText"

// import { productTypes } from "@/store/constants"

export default {
  props: ["lineItem"],

  data: () => ({
    customerFreeGiftOptIn: true
  }),

  components: {
    RichText
  },

  created() {
    this.uiTextContent_manifest = [
      "cart_gwp_line_item_checkbox_in",
      "cart_gwp_line_item_checkbox_out"
    ]
    this.uiElement_manifest = ["cart_gwp_line_item_free"]
  },

  methods: {
    ...mapMutations("cart", ["SET_CUSTOMER_GWP_OPT_IN"]),
    ...mapActions("cart", ["ADD_TO_CART", "REMOVE_FROM_CART"])
  },
  computed: {
    ...mapState("cart", ["customerGwpOptIn"]),

    ...mapGetters("cart", ["getGwpPromo", "getIsGwpPromoTriggered"]),

    ...mapGetters("content", [
      "GET_SITE_CONFIG",
      "GET_PRODUCT_ENTRY",
      "GET_PROMO_DATA_BY_PRODUCT",
      "GET_HAS_GLOBAL_PRICE_ADJUSTMENT"
    ]),

    getLineItemTitle() {
      return this.getPromoProduct.displayTitle
    },

    getLineItemPackshot() {
      return (
        this.getPromoProduct &&
        ((this.getPromoProduct.packshot && this.getPromoProduct.packshot[0]) ||
          (this.getPromoProduct.images && this.getPromoProduct.images[0]))
      )
    },

    getPromoProduct() {
      const promoProduct =
        (this.getGwpPromo &&
          this.getGwpPromo.promotionalProductsCollection &&
          this.getGwpPromo.promotionalProductsCollection.items[0]) ||
        null

      return (
        promoProduct &&
        this.GET_PRODUCT_ENTRY(promoProduct.sys.id, promoProduct.__typename)
      )
    },

    getOptInText() {
      return this.UI_TEXT_CONTENT(
        this.customerFreeGiftOptIn
          ? "cart_gwp_line_item_checkbox_in"
          : "cart_gwp_line_item_checkbox_out"
      )
    }
  },

  watch: {
    customerFreeGiftOptIn(newVal) {
      this.SET_CUSTOMER_GWP_OPT_IN(newVal)
    },

    getIsGwpPromoTriggered(newVal) {
      this.customerFreeGiftOptIn = newVal
    }
  }
}
</script>

<style lang="scss">
.bp-cart-drawer-gwp-entry {
  padding-top: 0;
  padding-bottom: 10px;
  margin-bottom: 10px;

  // &:not(:last-child) {
  //   border-bottom: 1px solid getcolour(bleach_grey_90);
  // }

  // width: calc(100% - 24px);

  @include media-breakpoint-up(md) {
    padding-bottom: 10px;
    margin-bottom: 10px;

    // & :not(:last-child) {
    //   border-bottom: 1px solid getcolour(bleach_grey_90);
    // }
    // width: calc(100% - 40px);
  }

  // // Because the last child will be the upsell component
  // &:nth-last-child(2) {
  //   margin-bottom: 24px;

  //   @include media-breakpoint-up(md) {
  //     margin-bottom: 32px;
  //   }
  // }

  &__flex-container {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    align-items: stretch;
  }

  &__image {
    background: rgba(getcolour(bleach_lime), 0.4);
    border: 1px solid transparent;
    width: 60px;
    height: 65px;

    @include media-breakpoint-up(md) {
      width: 72px;
      height: 78px;
    }

    &--opt-out {
      background: getcolour(bleach_white);
      border: 1px dashed rgba(getcolour(bleach_lime), 0.4);
    }
  }

  &__content {
    flex: 1;
    display: flex;
    // height: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1px;
  }

  &__content-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 16px;
    flex: 1 0 auto;

    @include media-breakpoint-up(md) {
      margin-left: 24px;
    }

    &:last-child {
      flex-wrap: nowrap;
      justify-content: flex-start;
      flex: 0 1 auto;
    }
  }

  &__content-title {
    width: calc(100% - 30px);
    text-wrap-style: balance;

    span {
      display: inline-block;
    }

    &--opt-out {
      opacity: 0.3;
    }
  }

  &__content-title--subscription {
    margin-bottom: 0;
  }

  &__remove-item {
    width: 16px;
    height: 16px;
  }

  &__price {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  &__price-amount {
  }

  &__checkbox-text {
    flex: 1 1 auto;

    p {
      margin-bottom: 0;
    }
  }
}
</style>
